application.register("lib-gallery", class extends Stimulus.Controller {
   connect() {
       let self = this;
       let element = self.element;

       if (!document.documentElement.classList.contains("ie") && !element.classList.contains("is-inview")) {
           let callback = function (mutationsList, observer) {
               for (let mutation of mutationsList) {
                   if (element.classList.contains("is-inview")) {
                       observer.disconnect();
                       self.createGallery();
                   }
               }
           };
           let observer = new MutationObserver(callback);
           observer.observe(element, {attributes: true, attributeFilter: ['class']});
       } else {
           self.createGallery();
       }
   }

   createGallery() {
       let self = this;

       importScript([cdnjs.jquery, cdnjs.fancybox], ()=> {
           importStyle(cdnjs.fancybox_css);
           cssLoaded(()=> {
               let elm = $(self.element);
               elm.fancybox({
                   selector: `[data-lib-gallery-id="${self.data.get("id")}"] [data-lib-gallery-item]`,
                   transitionEffect: "slide",
                   animationEffect: "zoom",
                   hideScrollbar: false,
                   loop: true,
                   buttons: [
                       "close"
                   ]
               });
           });
       })
   }
});