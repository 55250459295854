application.register("lib-recaptcha", class extends Stimulus.Controller {
    connect() {
        setTimeout(() => this.captcha(this), 2500);

        this.interval = setInterval(() => this.captcha(this), 150000);
    }

    captcha(self) {
        importScript(cdnjs.recaptcha.replace("{apikey}",self.data.get("api")), function(){
            grecaptcha.enterprise.ready(() => {
                grecaptcha.enterprise.execute(self.data.get("api"), { action: 'form' }).then(token => {
                    self.element.querySelector(`[name="gtoken"]`).value = token;
                })
            })
        })

    }

    disconnect() {
        clearInterval(this.interval);
    }
});
