application.register("comp_conferences", class extends Stimulus.Controller {
    get ref() {
        return {
            nav: this.targets.find("nav"),
            body: this.targets.find("body"),
        }
    }

    connect() {
        cssLoaded(()=>{
            if(typeof locomotive !== "undefined") {
                let sections = this.ref.body.querySelectorAll("[data-offset]");
                let offsets = {};
                let controller_offset = this.element.offsetTop;

                Array.from(sections).forEach((section)=>{
                    let offset = (typeof section.dataset.offset !== "undefined") ? parseInt(section.dataset.offset) : 0;
                    offsets[section.getAttribute("id")] = section.offsetTop + controller_offset - offset;
                });

                locomotive.on("scroll",(e)=>{
                    let position = (!locomotive.isMobile) ? e.delta.y : window.scrollY;

                    for(const id in offsets) {
                        if(offsets[id] <= position) {
                            let active = this.ref.nav.querySelector(".state--active")
                            if(active !== null) {
                                active.classList.remove("state--active");
                            }
                            this.ref.nav.querySelector(`[href="#${id}"]`).classList.add("state--active");
                        }
                    }
                });
            }
        })
    }
});