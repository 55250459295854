application.register("lib-drawer", class extends Stimulus.Controller {

    get ref() {
        return {
            nav: this.targets.find("nav")
        }
    }

    init() {
        setTimeout(() => this.ref.nav.classList.add("is--transition"), 50);
        this.ref.nav.addEventListener("click", (e) => {
            if (e.target === this.ref.nav) {
                this.hide();
            }
        })
    }

    show() {
        this.ref.nav.scrollLeft = 0;
        this.ref.nav.style.setProperty('--drawerOpacity', `1`);
        this.ref.nav.classList.add("is--opacity");
        this.ref.nav.classList.add("state--active");
        document.documentElement.classList.add("drawer-active");
        document.documentElement.style["overflow"] = "hidden";
    }

    hide() {
        this.ref.nav.classList.remove("state--active");
        this.ref.nav.classList.add("is--opacity");
        this.ref.nav.style.setProperty('--drawerOpacity', `0`);
        document.documentElement.classList.remove("drawer-active");
        document.documentElement.style["overflow"] = "";
    }

    scroll(e) {
        let opacity = Math.abs((e.target.scrollLeft / this.ref.nav.children[0].clientWidth) - 1);

        if (e.target.scrollLeft > 1) {
            this.ref.nav.classList.remove("is--opacity");
            this.ref.nav.style.setProperty('--drawerOpacity', `${opacity}`);
        }

        if (e.target.scrollLeft === this.ref.nav.children[0].clientWidth) {
            this.ref.nav.classList.remove("state--active");
            document.documentElement.style["overflow"] = null;
        }
    }
});