function redrawSnippetItem(payload, i, htmlSnippet) {

    let redrawWholeComponent = true;

    if (typeof payload.redrawBlocks !== 'undefined' && typeof payload.redrawBlocks[i] !== 'undefined' && payload.redrawBlocks[i].length) {
        redrawWholeComponent = false;

        for (let iBlock in payload.redrawBlocks[i]) {
            let objectSnippet = new DOMParser().parseFromString(htmlSnippet, 'text/html').body.firstChild;

            let idToFind = payload.redrawBlocks[i][iBlock].block;
            let insertType = payload.redrawBlocks[i][iBlock].insertType;
            let sourceBlock = payload.redrawBlocks[i][iBlock].sourceBlock;
            let htmlToReplace;

            if(sourceBlock) {
                htmlToReplace = objectSnippet.querySelector("#" + sourceBlock);
                if(htmlToReplace !== null) {
                    htmlToReplace.setAttribute('id',idToFind);
                }
            } else if (objectSnippet.getAttribute("id") === idToFind) {
                htmlToReplace = objectSnippet;
            } else {
                htmlToReplace = objectSnippet.querySelector("#" + idToFind);
            }

            if (htmlToReplace === null) {
                continue;
            }

            switch(insertType) {
                case 'default':
                    console.log("we are here");
                    document.querySelector("#" + idToFind).outerHTML = htmlToReplace.outerHTML;
                    break;
                case 'append':

                    if(htmlToReplace) {
                        document.querySelector("#" + idToFind).insertAdjacentHTML("beforeend", htmlToReplace.innerHTML);
                    }
                    break;
            }

            loadStimulus(document.getElementById(idToFind));
        }
    }

    if (redrawWholeComponent) {
        let snippetItem = new DOMParser().parseFromString(htmlSnippet, 'text/html').body.firstChild;
        if (snippetItem.getAttribute("id") === i) {
            document.querySelector("#" + i).outerHTML = snippetItem.outerHTML;
        } else {
            let snippet = snippetItem.querySelector("#" + i);
            document.querySelector("#" + i).outerHTML = snippet.outerHTML;
        }

        loadStimulus(document.getElementById(i));
    }
}

function fetchHandler(payload, callback) {
    if (payload) {
        // přesměrování "this" přesměruje na aktuální stránku
        if (payload.redirect) {
            if (payload.redirect === 'this') {
                location.href = window.location.href.split("#")[0];
            } else {
                location.href = payload.redirect;
            }
        }

        // výměna snippetů v šabloně - každý snippet musí mít unikátní ID
        if (payload.snippets) {
            for (let i in payload.snippets) {

                if (typeof payload.snippets[i] === 'object') {
                    for (let iSnippet in payload.snippets[i]) {
                        redrawSnippetItem(payload, i, payload.snippets[i][iSnippet]);
                    }
                } else {
                    redrawSnippetItem(payload, i, payload.snippets[i]);
                }
            }
        }

        // výměna adresy v adresním řádku
        if (payload.replaceState) {
            window.history.replaceState(null, payload.replaceState.title || null, payload.replaceState.url);

            if (payload.replaceState.title) {
                document.title = payload.replaceState.title;
            }

            if (typeof ga === "function") {
                ga('send', 'pageview', window.location.pathname + window.location.search);
            }

            if (typeof gtag === 'function') {
                gtag('event', 'page_view', {'page_path': window.location.pathname + window.location.search});
            }
        }

        if (callback) {
            callback();
        }
    }
}