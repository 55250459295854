application.register("comp_booking", class extends Stimulus.Controller {
    connect() {
        const iframe = this.element.querySelector('#bookolo-engine')

        if(iframe !== null) {
            if (localStorage.getItem('lib-cookieconsent') !== null) {
                iframe.setAttribute('data-cookieconsent', localStorage.getItem('lib-cookieconsent').replace(/[\[\]'"]/g,''))
            }
        }
    }
});
