const cdnjs = {
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "recaptcha": "https://www.google.com/recaptcha/enterprise.js?render={apikey}",
    "seamless": "https://cdn.jsdelivr.net/npm/seamless-scroll-polyfill@1.0.9/dist/es5/seamless.min.js",
    "jquery": "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.min.js",
    "popper": "https://cdn.jsdelivr.net/npm/@popperjs/core@2.1.1/dist/umd/popper.min.js",
    "tippy": "https://cdn.jsdelivr.net/npm/tippy.js@6.1.0/dist/tippy-bundle.umd.min.js",
    "tippy_css": "https://cdn.jsdelivr.net/combine/npm/tippy.js@6.1.0/themes/light.min.css,npm/tippy.js@6.1.0/animations/scale.min.css",
    "datepicker": "https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.0.3/dist/js/datepicker.min.js",
    "datepicker_i18n": "https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.0.3/dist/js/locales/cs.js",
    "datepicker_css": "https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.0.3/dist/css/datepicker.min.css",
    "airdatepicker": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/datepicker.min.js",
    "airdatepicker_i18n": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/i18n/datepicker.cs.js",
    "airdatepicker_css": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/css/datepicker.min.css",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js"
};
