application.register("layout_social", class extends Stimulus.Controller {
    get offsetTop() {
        if (this._offsetTop === undefined) {
            this._offsetTop = 0
        }
        return this._offsetTop
    }
    connect() {
        let self = this,
            currentScrollY = 0,
            ticking = false;

        function setHeaderState() {
            if (currentScrollY >= (self._offsetTop - window.innerHeight/2)) {
                self.element.classList.add("is--not-top");
            } else {
                self.element.classList.remove("is--not-top");
            }
        }

        function update() {
            setHeaderState();
            ticking = false;
        }

        function onScroll() {
            if(!ticking) {
                requestAnimationFrame(update);
                ticking = true;
            }
        }

        bodyLoaded(()=>{
            setTimeout(()=>{
                let self = this,
                    visual = document.querySelector("#layout_main").children[0];

                if(visual !== null && visual.classList.contains("comp_visual")) {
                    self._offsetTop = visual.clientHeight;
                }
                else {
                    self._offsetTop = 0;
                }
                onScroll();
                if(typeof locomotive !== "undefined" && !locomotive.isMobile) {
                    locomotive.on("scroll",(e)=>{
                        currentScrollY = e.delta.y;
                        onScroll();
                    });
                }
                else {
                    window.addEventListener('scroll', ()=>{
                        currentScrollY = window.scrollY;
                        onScroll();
                    }, false);
                }
            },100);
        })
    }
});