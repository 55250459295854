application.register("layout_header", class extends Stimulus.Controller {
    connect() {
        let header = this.element,
            currentScrollY = 0,
            latestKnownScrollY = 0,
            ticking = false,
            offset = 5;

        if (document.querySelector("#layout_nav") === null) {
            this.element.insertAdjacentHTML("afterend", `<div id="layout_nav" class="lib--drawer" data-target="lib-drawer.nav" data-action="scroll->lib-drawer#scroll"><div class="wrp_nav"><div class="wrp_nav_head"></div><div class="wrp_nav_body"></div></div></div>`);


            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").init()

            let layout_nav = document.querySelector("#layout_nav"),
                logo = this.element.querySelector(".elm_header_logo").outerHTML,
                nav = this.element.querySelector(".elm_header_nav").outerHTML;

            layout_nav.querySelector(".wrp_nav_head").insertAdjacentHTML("beforeend", logo);
            layout_nav.querySelector(".wrp_nav_body").insertAdjacentHTML("beforeend", nav);
        }

        function setHeaderState() {
            if (currentScrollY >= offset) {
                header.classList.add("header--not-top");
                if (currentScrollY > latestKnownScrollY){
                    header.classList.remove("header--pinned");
                    header.classList.add("header--unpinned");
                } else {
                    header.classList.remove("header--unpinned");
                    header.classList.add("header--pinned");
                }
            } else {
                header.classList.remove("header--not-top", "header--pinned", "header--unpinned");
            }
            latestKnownScrollY = currentScrollY;
        }

        function update() {
            setHeaderState();
            ticking = false;
        }

        function onScroll() {
            if(!ticking) {
                requestAnimationFrame(update);
                ticking = true;
            }
        }

        bodyLoaded(()=>{
            setTimeout(()=>{
                if(typeof locomotive !== "undefined" && !locomotive.isMobile) {
                    locomotive.on("scroll",(e)=>{
                        currentScrollY = e.delta.y;
                        onScroll();
                    });
                }
                else {
                    window.addEventListener('scroll', ()=>{
                        currentScrollY = window.scrollY;
                        onScroll();
                    }, false);
                }
            },100);
        })
    }
});